// Logo.js
import React from 'react';
import LogoImg from './../images/Logo.svg'

const Logo = () => {
  return (
    <div className="logo">
      <img src={LogoImg} alt="Company Logo" />
    </div>
  );
};

export default Logo;
