// TextContent.js
import React from 'react';

const TextContent = ({ heading, paragraph }) => {
  return (
    <>
        <h2>{heading}</h2>
        <p>{paragraph}</p>
      </>
  );
};

export default TextContent;
