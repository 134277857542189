import React, { useState } from "react";
import InputComponent from "../../component/Input";
import ButtonComponent from "../../component/Button";
import TextContent from "../../component/Leftcontent";
import Checkbox from "../../component/Checkbox";
import CountrySelect from "../../component/CountrySelect";
import Logo from "../../component/Logo";

export default function Register() {
  const [inputValue, setInputValue] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };
  const handleClick = () => {
    // Handle the button click event here
    console.log("Button clicked");
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  const contentArray = [
    {
      text: "I agree to all the ",
      link: "https://example.com/terms",
      linkText: "Terms",
    },
    {
      text: ",",
    },
    {
      text: " ",
    },
    {
      link: "https://example.com/privacy",
      linkText: "Privacy Policy",
    },
    {
      text: " ",
    },

    {
      text: " and ",
    },
    {
      link: "https://example.com/fees",
      linkText: "Fees",
    },
    {
      text: ".",
    },
  ];

  const handleCountryChange = (value) => {
    setSelectedCountry(value);
  };
  return (
    <>
      <div className="login_main">
        <div className="container">
          <div className="logo_part mb-4">
            <Logo/>
          </div>
          <div className="login_form_part">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="login_left">
                  <TextContent
                    heading="Lorem Ipsum is simply dummy text"
                    paragraph="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text 
					since the 1500s"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="login_right bg-white p-5">
                  <h2>Register</h2>
                  <InputComponent
                    label="First Name"
                    type="text"
                    placeholder="Enter Your First Name"
                    value={inputValue}
                    onChange={handleInputChange}
                    className="mb-3"
                  />

                  <InputComponent
                    label="Last Name"
                    type="text"
                    placeholder="Enter Your Last Name"
                    value={inputValue}
                    onChange={handleInputChange}
                    className="mb-3"
                  />
                  <InputComponent
                    label="Email Id"
                    type="text"
                    placeholder="Enter Your Email Id"
                    value={inputValue}
                    onChange={handleInputChange}
                    className="mb-3"
                  />
                  <InputComponent
                    label="Phone Number"
                    type="text"
                    placeholder="Enter Your Phone Number"
                    value={inputValue}
                    onChange={handleInputChange}
                    className="mb-3"
                  />
                  <CountrySelect
                    value={selectedCountry}
                    onChange={handleCountryChange}
                    label="Location"
                  />
                  <Checkbox
                    contentArray={contentArray}
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                    className="me-3 mt-3"
                  />
                  <ButtonComponent
                    label="Register"
                    onClick={handleClick}
                    className="primery_btn mt-4 mb-2" // Optional CSS class
                  />
                  <div className="d-flex mt-2 w-100 justify-content-center">
                    <p className="mb-0">
                      Already have an Account? <a href="/">Login</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
