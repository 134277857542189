// ButtonComponent.js
import React from 'react';

const ButtonComponent = ({ label, onClick, className, icon }) => {
  return (
    <button className={className} onClick={onClick}>
        {icon && <img src={icon} alt="Icon" className="icon" />}
        {label}
    </button>
  );
};

export default ButtonComponent;
