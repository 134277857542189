// InputComponent.js
import React from 'react';

const InputComponent = ({ label, type, placeholder, value, onChange, className }) => {
  return (
    <div className="input-container">
      <label>{label}</label>
      <input
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        className={className}
      />
    </div>
  );
};

export default InputComponent;
