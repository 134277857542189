import React, { useState } from "react";
import "./Login.css";
import InputComponent from "../../component/Input";
import ButtonComponent from "../../component/Button";
import GoggleIcon from "./../../images/flat-color-icons_google.svg";
import TextContent from "../../component/Leftcontent";
import Logo from "../../component/Logo";
import { Link } from "react-router-dom";


export default function Login() {
  const [inputValue, setInputValue] = useState("");
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };
  const handleClick = () => {
    // Handle the button click event here
    console.log("Button clicked");
  };
  
  return (
    <>
      <div className="login_main vh-100">
        <div className="container">
          <div className="logo_part mb-4">
            <Logo />
          </div>
          <div className="login_form_part">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="login_left">
                  <TextContent
                    heading="Lorem Ipsum is simply dummy text"
                    paragraph="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text 
					since the 1500s"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="login_right bg-white p-5">
                  <h2>Sign in</h2>
                  <InputComponent
                    label="Enter Your Mobile Number"
                    type="text"
                    value={inputValue}
                    onChange={handleInputChange}
                  />
                  <ButtonComponent
                    label="Continue"
                    onClick={handleClick}
                    className="primery_btn mt-4 mb-2" // Optional CSS class
                  />
                  <div className="d-flex w-100 justify-content-center">
                    <p className="mb-0">Or Continue with</p>
                  </div>
                  <ButtonComponent
                    label="Sigin in with google"
                    onClick={handleClick}
                    className="mt-2 w-100 google_btn" // Optional CSS class
                    icon={GoggleIcon}
                  />
                  <div className="d-flex mt-2 w-100 justify-content-center">
                    <p className="mb-0">
                      Don't have an account? <Link to="/register">Register</Link>
                    </p>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
