import React, { useState } from "react";
import { InputOTP } from "antd-input-otp";
import { Button, Form } from "antd";
import ButtonComponent from "../../component/Button";
import TextContent from "../../component/Leftcontent";
import Logo from "../../component/Logo";

export default function Otp() {
  const [otpValues, setOtpValues] = useState([]);
  // #endregion

  // #region The Uncontrolled Logic
  const [form] = Form.useForm();

  const handleFinish = (values) => {
    // The value will be array of string
    // Check the field if there is no value, or value is undefined/empty string
    const { otp } = values;
    if (!otp || otp.includes(undefined) || otp.includes(""))
      return form.setFields([
        {
          name: "otp",
          errors: ["OTP is invalid."],
        },
      ]);

    console.log(`OTP: ${otp}`);
  };
  const handleClick = () => {
    // Handle the button click event here
    console.log("Button clicked");
  };
  return (
    <>
      <div className="login_main vh-100">
        <div className="container">
          <div className="logo_part mb-4">
            <Logo />
          </div>
          <div className="login_form_part">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="login_left">
                  <TextContent
                    heading="Lorem Ipsum is simply dummy text"
                    paragraph="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text 
					since the 1500s"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="login_right bg-white p-5">
                  <h2>OTP Verification</h2>
                  <p>
                    A 6-digit code has been sent to your mobile number 97260****
                  </p>
                  <Form form={form} onFinish={handleFinish}>
                    <Form.Item
                      name="otp"
                      className="center-error-message"
                      rules={[{ validator: async () => Promise.resolve() }]}
                    >
                      <InputOTP autoFocus inputType="numeric" length={6} />
                    </Form.Item>

                    <Form.Item noStyle>
                      <ButtonComponent
                        label="Verify OTP"
                        onClick={handleClick}
                        className="primery_btn mt-4 mb-2" // Optional CSS class
                      />
                      <div className="d-flex mt-2 w-100 justify-content-start">
                        <p className="mb-0">
                          Didn’t receive the code? <a href="/">Resend</a>
                        </p>
                      </div>
                    </Form.Item>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
