// Checkbox.js
import React from 'react';

const Checkbox = ({ contentArray, checked, onChange, className }) => {
  return (
    <label>
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        className={className}
      />
      {contentArray.map((item, index) => (
        <span key={index}>
          {item.text}
          {item.link && (
            <a href={item.link} target="_blank" rel="noopener noreferrer">
              {item.linkText}
            </a>
          )}
          {index < contentArray.length - 1 && <span>  </span>}
        </span>
      ))}
    </label>
  );
};

export default Checkbox;
