// CountrySelect.js
import React from 'react';
import Select from 'react-select';
import countries from './countries'; // Import your countries.js file

const CountrySelect = ({ label, value, onChange }) => {
  const countryOptions = countries.map((country) => ({
    value: country.code,
    label: country.name,
  }));

  // Find the selected option based on the current value
  const selectedOption = countryOptions.find((option) => option.value === value);

  return (
    <div>
      <div className='input-container'><label>{label}</label> </div>
      <Select
        value={selectedOption} // Set the selected option directly
        onChange={(selectedOption) => onChange(selectedOption ? selectedOption.value : null)}
        options={countryOptions}
        isSearchable={true}
        placeholder="Select a country..."
      />
    </div>
  );
};

export default CountrySelect;
